.paginate-pagination {
  margin-top: 40px;
}
.paginate-pagination ul {
  padding-left: 0;
}
.paginate-pagination li {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 0 5px;
}
.paginate-pagination li a.deactive {
  pointer-events: none;
}
.paginate-pagination li a,
.paginate-pagination li span {
  display: inline-block;
  padding: 8px 18px;
  text-decoration: none;
  border: 0;
  font-family: "Roboto Slab", "Open Sans";
  font-weight: 400;
  font-size: 1.1875rem;
  color: inherit;
}
.paginate-pagination li a.active,
.paginate-pagination li span.active {
  background-color: #ebebeb;
}

#article-container .location-pagination .pagination .page-item .active {
  background-color: #ebebeb !important;
  border-color: #ebebeb !important;
}
#article-container .location-pagination .pagination .page-item .bg-btn-disabled {
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  color: #9c9c9c !important;
}