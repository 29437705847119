.paginate-pagination {
  margin-top: 40px;
  ul {
    padding-left: 0;
  }
  li {
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 0 5px;

    a.deactive {
      pointer-events: none;
    }

    a,
    span {
      display: inline-block;
      padding: 8px 18px;
      text-decoration: none;
      border: 0;
      font-family: 'Roboto Slab', 'Open Sans';
      font-weight: 400;
      font-size: 1.1875rem;
      color: inherit;

      &.active {
        background-color: #ebebeb;
      }
    }
  }
}
#article-container {
  .location-pagination {
    .pagination {
      .page-item {
        .active {
          background-color: #ebebeb !important;
          border-color: #ebebeb !important;
        }
        .bg-btn-disabled {
          background-color: #cfcfcf !important;
          border-color: #cfcfcf !important;
          color: #9c9c9c !important;
        }
      }
    }
  }
}
